"use strict";

import { CATEGORY_METADATA } from "../components/layout/useCategory";
import { ModuleType } from "../components/layout/useModuleType";
import { Category } from "../components/mutation_view/problem/__generated__/ProblemMutationViewProblemRelationsAutoCompleteQuery.graphql";

export function getCategoryFromPathname(pathname: string): Category | null {
  try {
    return getCategoryFromBasePath(pathname.substring(1).split("/", 1)[0]);
  } catch {
    return null;
  }
}

export function getCategoryFromBasePath(basePath: string): Category {
  const entry = Object.entries(CATEGORY_METADATA).find(
    ([, { path }]) => path === basePath,
  );
  if (entry == null) {
    throw new Error(`Unsupport basePath ${basePath}`);
  }
  return entry[0] as Category;
}

export function getModuleTypeFromPathname(pathname: string): ModuleType | null {
  const category = getCategoryFromPathname(pathname);
  if (category == null) {
    return null;
  }
  try {
    const path = pathname.substring(1).split("/", 2)[1];
    if (path == null) {
      return ModuleType.QUESTIONS;
    }
    switch (path) {
      case "":
        return ModuleType.QUESTIONS;
      case "mock_interview":
        return ModuleType.MOCK_INTERVIEW;
      default:
        throw new Error(`Unsupport module type ${path}`);
    }
  } catch {
    return null;
  }
}
