"use strict";

import { FC, ReactElement, useMemo, useState } from "react";
import LayoutTopBar from "./top_bar/LayoutTopBar";
import LayoutContext from "./LayoutContext";
import { useMeasure } from "@uidotdev/usehooks";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { LayoutQuery } from "./__generated__/LayoutQuery.graphql";
import { Alert } from "@mui/material";

interface Props {
  children: React.ReactNode;
}

const Layout: FC<Props> = ({ children }: Props): ReactElement => {
  const [shouldTopBarProgressUpdate, setShouldTopBarProgressUpdate] =
    useState(false);
  const [shouldSearchViewTableUpdate, setShouldSearchViewTableUpdate] =
    useState(false);
  const [ref, { height }] = useMeasure();

  const { alert } = useLazyLoadQuery<LayoutQuery>(
    graphql`
      query LayoutQuery {
        alert
      }
    `,
    {},
  );

  const contextValue = useMemo(
    () => ({
      topBarHeight: height,
      shouldTopBarProgressUpdate,
      setShouldTopBarProgressUpdate,
      shouldSearchViewTableUpdate,
      setShouldSearchViewTableUpdate,
    }),
    [height, shouldSearchViewTableUpdate, shouldTopBarProgressUpdate],
  );

  return (
    <LayoutContext.Provider value={contextValue}>
      <span ref={ref} style={{ display: "block" }}>
        <LayoutTopBar />
        {alert != null && <Alert severity="warning">{alert}</Alert>}
      </span>
      {children}
    </LayoutContext.Provider>
  );
};

export default Layout;
