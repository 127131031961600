export interface ApiConfig {
  baseUrl: string;
  iconBaseUrl: string;
  imageBaseUrl: string;
  videoBaseUrl: string;
}

export interface AppConfig {
  api: ApiConfig;
  auth0: {
    clientID: string;
    domain: string;
    audience: string;
  };
}

const config: AppConfig = {
  api: {
    baseUrl: `${window.ENV_CONFIG.REACT_APP_API_BASE_URL ?? "https://localhost:3100"}/graphql`,
    iconBaseUrl: `${window.ENV_CONFIG.REACT_APP_STORAGE_BLOB_CDN_ENDPOINT}/audioblobcontainer/icon`,
    imageBaseUrl: `${window.ENV_CONFIG.REACT_APP_STORAGE_BLOB_CDN_ENDPOINT}/audioblobcontainer/image`,
    videoBaseUrl: `${window.ENV_CONFIG.REACT_APP_STORAGE_BLOB_CDN_ENDPOINT}/audioblobcontainer/video`,
  },
  auth0: {
    clientID: window.ENV_CONFIG.REACT_APP_AUTH0_CLIENT_ID,
    domain: window.ENV_CONFIG.REACT_APP_AUTH0_DOMAIN,
    audience: window.ENV_CONFIG.REACT_APP_AUTH0_AUDIENCE,
  },
};

export default config;
