"use strict";

import { FC, ReactElement, useMemo } from "react";
import {
  ChartsTooltip,
  DefaultChartsItemTooltipContent,
  PiePlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import useTheme from "../../../../theme/useTheme";
import { DifficultyProblemStats } from "./LayoutTopBarProblemUnitViewProgress";
import { SeriesProviderProps } from "@mui/x-charts/internals";
import { useMeasure } from "@uidotdev/usehooks";

interface Props {
  difficultyProblemStats: DifficultyProblemStats[];
}

const LayoutTopBarProblemUnitViewProgressPieChart: FC<Props> = ({
  difficultyProblemStats,
}): ReactElement => {
  const [ref, { width, height }] = useMeasure();
  const { theme } = useTheme();

  const outerRadius = useMemo(
    () => Math.min(width ?? 0, height ?? 0) / 2,
    [width, height],
  );

  const [totalCompletedCount, totalCount] = useMemo(
    () =>
      difficultyProblemStats.reduce(
        (acc, { count, totalCount }) => [acc[0] + count, acc[1] + totalCount],
        [0, 0],
      ),
    [difficultyProblemStats],
  );

  const series: SeriesProviderProps["series"] = useMemo(
    () => [
      {
        id: "completed",
        type: "pie",
        data: difficultyProblemStats.map(
          ({ difficulty, label, count, color }) => ({
            id: difficulty,
            value: count,
            label,
            color,
          }),
        ),
        startAngle: 0,
        endAngle: 360 * (totalCompletedCount / totalCount),
        innerRadius: outerRadius * 0.8,
        outerRadius,
      },
      {
        id: "uncompleted",
        type: "pie",
        data: [
          {
            value: totalCount - totalCompletedCount,
            color: `${theme.palette.grey[800]}1A`,
          },
        ],
        startAngle: 360 * (totalCompletedCount / totalCount),
        endAngle: 360,
        innerRadius: outerRadius * 0.8,
        outerRadius,
      },
    ],
    [
      difficultyProblemStats,
      outerRadius,
      theme.palette.grey,
      totalCompletedCount,
      totalCount,
    ],
  );

  return (
    <ResponsiveChartContainer ref={ref} series={series}>
      <PiePlot skipAnimation={true} />
      <text
        x={(width ?? 0) / 2}
        y={(height ?? 0) / 2 - 8}
        fill={theme.palette.text.primary}
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={12}
      >
        {totalCompletedCount} / {totalCount}
      </text>
      <text
        x={(width ?? 0) / 2}
        y={(height ?? 0) / 2 + 8}
        fill={theme.palette.text.primary}
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={12}
      >
        Total
      </text>
      <ChartsTooltip
        trigger="item"
        slots={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore[2322]
          itemContent: (props) =>
            props.series.id === "completed" && (
              <DefaultChartsItemTooltipContent {...props} />
            ),
        }}
      />
    </ResponsiveChartContainer>
  );
};

export default LayoutTopBarProblemUnitViewProgressPieChart;
